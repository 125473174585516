import { Grid } from "@mui/material";

export interface ILabelAndAnyDisplayProps {
  label?: string | undefined;
  value?: React.ReactElement;
  hasColonSeparator?: boolean | undefined;
  align?: "left" | "right" | "center";
}

const LabelAndAnyDisplay: React.FC<ILabelAndAnyDisplayProps> = ({
  label,
  value,
  hasColonSeparator = true,
  align = "center"
}) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    fontSize: "1.5vh",
    paddingBottom: "1em",
    flexWrap: "wrap",
  } as React.CSSProperties;

  const labelStyle = {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight:".5vw"
  } as React.CSSProperties;

  const valueStyle = {
    textAlign: align as React.CSSProperties["textAlign"],
    wordWrap: "break-word",
    overflowWrap: "anywhere",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: 1, // Allow the value to take up remaining space
  } as React.CSSProperties;

  return (
    <Grid item xs={12} style={containerStyle}>
      {label && (
        <Grid item style={labelStyle}>
          {label}
          {hasColonSeparator && ":"}
        </Grid>
      )}
      {value && (
        <Grid item style={valueStyle}>
          {value}
        </Grid>
      )}
    </Grid>
  );
};

export default LabelAndAnyDisplay;
