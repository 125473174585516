import { Grid } from "@mui/material";

export interface ILabelAndTextDisplayProps {
  label?: string | undefined;
  text?: (string | undefined)[] | string | undefined;
  hasColonSeparator?: boolean | undefined;
  align?: "left" | "right" | "center";
  noPadding?: boolean;
}

const LabelAndTextDisplay: React.FC<ILabelAndTextDisplayProps> = ({
  label,
  text,
  hasColonSeparator = true,
  align = "center",
  noPadding = false
}) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    fontSize: "1.5vh",
    paddingBottom: noPadding? "0" : "1em",
    flexWrap: "wrap",
  } as React.CSSProperties;

  const labelStyle = {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight:".5vw"
  } as React.CSSProperties;

  const textStyle = {
    textAlign: align as React.CSSProperties["textAlign"],
    wordWrap: "break-word",
    overflowWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as React.CSSProperties;

  return (
    <Grid item xs={12} style={containerStyle}>
      {label && (
        <Grid item style={labelStyle}>
          {label}
          {hasColonSeparator && ":"}
        </Grid>
      )}
      {text && (
        <Grid item style={textStyle}>
          {!Array.isArray(text) && text}
          {Array.isArray(text) &&
            text.map((t, i) => (
              <Grid item xs={12} key={`text-${i}`} style={textStyle}>
                {t}
              </Grid>
            ))}
        </Grid>
      )}
    </Grid>
  );
};

export default LabelAndTextDisplay;