import React, { useContext } from "react";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { Testimonial } from "../../Domain/Testimonial";
import { AuthContext } from "../Authentication";

export interface IExpandedTestimonial {
    testimonial: Testimonial;
    onVisibilityChanged: (testimonialId: string, isVisible: boolean) => void;
}

const AssistanceRequestListExpanded: React.FC<IExpandedTestimonial> = ({testimonial, onVisibilityChanged}) => {
  const user = useContext(AuthContext);  

  return (
    <Grid container>
        <Grid item xs={12}>
            {testimonial.statement}
        </Grid>
        {user?.isReadWriteAdministrator && (
            <Grid item xs={12}>
                <FormControlLabel control={<Switch onChange={(e) => onVisibilityChanged(testimonial.Id, e.target.checked)} checked={testimonial.webVisibilityStatus}/>} label="Make visible on website" />
            </Grid>
        )}
    </Grid>
  );
};
export default AssistanceRequestListExpanded;
