import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import TshirtImage from "../../Images/tshirt.jpeg"


const TShirtImage = styled('img')(({ theme }) => ({
    width: '80%',
    height: 'auto',
    borderRadius: '8px',
}));

const TshirtSaleMainContainer = styled(Grid)(({theme}) => ({
    backgroundColor: "#95A98D",
    fontSize: "4em",
    padding:".5em",
    wordWrap: "break-word",
    [theme.breakpoints.down("md")]: {
        fontSize: "1.5em",
    },
}));

const TShirtSales: React.FC = () => {
  return (
    <TshirtSaleMainContainer container justifyContent="center">
        <Grid item xs={12}>This holiday season support Be The Change Gulf Coast by purchasing a seasonal T-Shirt!</Grid>
        <Grid item xs={4}><TShirtImage src={TshirtImage}/></Grid>
        <Grid item xs={12}>Contact Madeline Culpepper to order a t-shirt.</Grid>
        <Grid item xs={12}><a href="tel: +12514025314" style={{textDecoration:"none", color:"inherit"}}>(251) 402-5314</a></Grid>
        <Grid item xs={12}><a href="mailto: madelineculpepper@robertsbrothers.com" style={{textDecoration:"none", color:"inherit"}}>madelineculpepper@robertsbrothers.com</a></Grid>
    </TshirtSaleMainContainer>
  );
};

export default TShirtSales;
