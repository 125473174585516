import Alert from "../Domain/Alert";
import { ApplicationStatus } from "../Domain/ApplicationStatus";
import AssistanceRequest, { ConvertServerModelToAssistanceRequest } from "../Domain/AssistanceRequest";
import { ButterCampaign, ButterTransaction, ButterPlan } from "../Domain/ButterInfo";
import { Logo, UpdateLogo } from "../Domain/Logo";
import { S3Doc, S3DocRequest, S3DocResponse } from "../Domain/S3Doc";
import { TaxationForm, UpdateTaxationForm } from "../Domain/TaxationForm";
import { ConvertServerModelToTestimonial, ConvertServerModelToTestimonialForOurPurpose, Testimonial, TestimonialForOurPurpose } from "../Domain/Testimonial";
import { ConvertServerModelToUserProfile, UserProfileType } from "../Domain/UserProfile";
import { VerificationStatus } from "../Domain/VerificationStatus";
import { ParseZipIntoListOfFiles, ZipUpFileList } from "../Helpers/FileHelper";

const clientConnectionBase = process.env.REACT_APP_APIGATEWAY_BASEURL ?? "https://google.com/";

const getOptionsForClientWithAuth = (method: string, body: any, token: string): RequestInit => {
    return {
        method: method,
        body: body ? JSON.stringify(body) : null,
        headers: {
            Authorization: 'Bearer ' + token,
        }
    } as RequestInit;
};

const getOptionsForClientWithoutAuth = (method: string, body: any): RequestInit => {
    return {
        method: method,
        body: body ? JSON.stringify(body) : null,
    } as RequestInit;
};

const getOptionsForS3Upload = (file: File): RequestInit => {
    return {
        method: "PUT",
        body: file,
        headers: {
            "Content-Type": file.type,
        },
    } as RequestInit;
};

export const createAssistanceRequest = async (token: string, currentRequest: AssistanceRequest) => {
    await fetch(clientConnectionBase + "assistancerequest/", getOptionsForClientWithAuth("POST", currentRequest, token)).then((res) => res.json());
}

export const createUserProfile = async (token: string, profile: UserProfileType, taxationForm: TaxationForm, logoImage?: Logo) => {
    if(taxationForm.file){
        var s3key = await uploadFileToS3(token, taxationForm.file);
        profile.s3Key = s3key;
    }
    if(logoImage?.file){
        var logoS3key = await uploadFileToS3(token, logoImage.file);
        profile.logoS3Key = logoS3key;
    }
    await fetch(clientConnectionBase + "userprofile", getOptionsForClientWithAuth("POST", profile, token));
}

export const createAlertForAdmins = async (token: string, alert: Alert) => {
    await fetch(clientConnectionBase + "alerts", getOptionsForClientWithAuth("PUT", alert, token));
}

export const uploadFileToS3 = async (token: string, upload: File): Promise<string> => {
    var response = await fetch(clientConnectionBase + "document", getOptionsForClientWithAuth("POST", { fileName: upload.name, type: upload.type }, token)).then(resp => resp.json());
    await fetch(response.url, getOptionsForS3Upload(upload));
    return response.s3key;
}

export const uploadFilesToS3 = async (token: string, uploads: File[]): Promise<string> => {
    var zip = await ZipUpFileList(uploads);
    return uploadFileToS3(token, zip);
}

export const deleteUpload = async (token: string, key: string) => {
    await fetch(clientConnectionBase + "document?s3key=" + key, getOptionsForClientWithAuth("DELETE", null, token));
}

export const deleteAlert = async (token: string, id: string) => {
    await fetch(clientConnectionBase + "alerts?alertId=" + id, getOptionsForClientWithAuth("DELETE", null, token));
}

export const getAssistanceRequests = async (token: string): Promise<AssistanceRequest[]> => {
    const response = await fetch(clientConnectionBase + "assistancerequests", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json()) as any[];    
    return response.map(r => ConvertServerModelToAssistanceRequest(r)) as AssistanceRequest[];
}

export const getAdminAssistanceRequests = async (token: string): Promise<AssistanceRequest[]> => {    
    var response = [] as any[];
    response = await fetch(clientConnectionBase + "assistancerequestsadmin", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json()) as any[];    
    return response.map(r => ConvertServerModelToAssistanceRequest(r)) as AssistanceRequest[];
}

export const getUserProfile = async (token: string): Promise<UserProfileType> => {
    var profile: UserProfileType;
    var response = await fetch(clientConnectionBase + "userprofile", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json());
    if (response.Id) {
        profile = ConvertServerModelToUserProfile(response);
    } else {
        throw new Error("Profile not found");
    }
    return profile;
}

export const getUserProfiles = async (token: string): Promise<UserProfileType[]> => {
    var response = await fetch(clientConnectionBase + "userprofiles", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json()) as any[];
    return response.map(r => ConvertServerModelToUserProfile(r)) as UserProfileType[];
}

export const getListOfFilesFromS3Key = async (token: string, documentKey: string): Promise<File[]> => {
    const s3ItemUrl = await fetch(clientConnectionBase + "document?s3key=" + documentKey, getOptionsForClientWithAuth("GET", null, token)).then((raw) => raw.json());
    const response = await fetch(s3ItemUrl).catch(err => {return undefined});
    const data = await response?.blob();
    if(data){
        const zipFile = new File([data], documentKey, {type: "application/zip"});
        return await ParseZipIntoListOfFiles(zipFile);
    }
    else {
        return [] as File[];
    }
}

export const getMultipleS3Zips = async (token: string, s3Requests: S3DocRequest[]): Promise<S3Doc[]> => {

    const response = await fetch(clientConnectionBase + "documents", getOptionsForClientWithAuth("POST", s3Requests, token)).then((raw) => raw.json());
    const s3ItemUrls = response as S3DocResponse[];
    const fetchPromises = s3ItemUrls.map(async (s3ItemUrl) => {
        try {
            const fileResponse = await fetch(s3ItemUrl.url);
            const dataBlob = await fileResponse.blob();
            const zipFile = new File([dataBlob], s3ItemUrl.s3Key, {type: "application/zip"});
            const fileList = await ParseZipIntoListOfFiles(zipFile);
            return {
                id: s3ItemUrl.id,
                s3Key: s3ItemUrl.s3Key,
                fileList: fileList
            } as S3Doc;
        } catch (err) {
            return undefined;
        }
    });

    const returndocs = await Promise.all(fetchPromises);
    return returndocs.filter(doc => doc !== undefined) as S3Doc[];
}

export const getS3Item = async (token: string, documentKey: string): Promise<File> => {
    var s3ItemUrl = await fetch(clientConnectionBase + "document?s3key=" + documentKey, getOptionsForClientWithAuth("GET", null, token)).then((raw) => raw.json());
    let response = await fetch(s3ItemUrl);
    let data = await response.blob();
    return new File([data], documentKey);
}

export const getAlerts = async (token: string): Promise<Alert[]> => {
    var returndata = [] as Alert[];
    var response = await fetch(clientConnectionBase + "alerts", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json());
    if (response && response.statusCode === 200) {
        response.forEach((item: any) => {
            returndata.push(item as Alert);
        });
    }
    return returndata;
}

export const updateUserProfile = async (token: string, profile: UserProfileType, newTaxationForm?: UpdateTaxationForm, newLogo?: UpdateLogo) => {
    if(newTaxationForm?.file){
        if(newTaxationForm.oldS3Key) {
            await deleteUpload(token, newTaxationForm.oldS3Key);
        }
        const newS3Key = await uploadFileToS3(token, newTaxationForm.file);
        profile.s3Key = newS3Key;
    }
    if(newLogo?.file){
        if(newLogo.oldS3Key){
            await deleteUpload(token, newLogo.oldS3Key);
        }
        const newS3KeyForLogo = await uploadFileToS3(token, newLogo.file);
        profile.logoS3Key = newS3KeyForLogo;
    }
    await fetch(clientConnectionBase + "userprofile", getOptionsForClientWithAuth("PUT", profile, token));
}

export const updateAssistanceRequestStatus = async (token: string, status: ApplicationStatus, requestId: string, completionDate: string, payoutNotes: string) => {
    var updateAssistanceRequest = {
      requestId: requestId,
      status: status,
      completionDate: completionDate,
      payoutNotes: payoutNotes
    };
    await fetch(clientConnectionBase + "assistancerequest", getOptionsForClientWithAuth("PUT", updateAssistanceRequest, token));
}

export const updateAssistanceRequestFileName = async (token: string, fileName: string, requestId: string) => {
    var updateAssistanceRequest = {
      requestId: requestId,
      fileName: fileName,
    };
    await fetch(clientConnectionBase + "assistancerequest", getOptionsForClientWithAuth("PATCH", updateAssistanceRequest, token));
}

export const getButterCampaigns = async (token: string): Promise<ButterCampaign[]> => {
    var results = (await fetch(clientConnectionBase + "butterCampaigns", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json()).catch(err => console.log(err))).data;
    return results.map((response: any) => {
        return response as ButterCampaign;
    });
}

export const getButterTransactions = async (token: string): Promise<ButterTransaction[]> => {
    var results = (await fetch(clientConnectionBase + "butterTransactions", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json()).catch(err => console.log(err))).data;
    if(results){
        return results.map((response: any) => {
            return response as ButterTransaction;
        });
    }
    else {
        return [] as ButterTransaction[];
    }
}

export const getButterPlans = async (token: string): Promise<ButterPlan[]> => {
    var results = (await fetch(clientConnectionBase + "butterPlans", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json()).catch(err => console.log(err))).data;
    if(results){
        return results.map((response: any) => {
            return response as ButterPlan;
        });
    }
    else {
        return [] as ButterPlan[];
    }
}

export const getButterDonorNumbers = async (): Promise<number> => {
    var number = await fetch(clientConnectionBase + "butter/donor/", getOptionsForClientWithoutAuth("GET", null)).then((res) => res.json()).catch(err => console.log(err));
    return number ?? 0;
}

export const updateUserVerification = async (token: string, userId: string, verificationStatus: VerificationStatus) => {
    var requestBody = {
        userId: userId,
        verificationStatus: verificationStatus
    };
    await fetch(clientConnectionBase + "userprofile", getOptionsForClientWithAuth("PATCH", requestBody, token));
}

export const getTestimonials = async (token: string): Promise<Testimonial[]> => {
    var testimonials = await fetch(clientConnectionBase + "testimonials", getOptionsForClientWithAuth("GET", null, token)).then((res) => res.json()).catch(err => console.log(err));
    return testimonials.map((testimonial:any) => ConvertServerModelToTestimonial(testimonial));
}

export const createTestimonial = async (token: string, testimonial: Testimonial) => {
    await fetch(clientConnectionBase + "testimonials", getOptionsForClientWithAuth("POST", testimonial, token));
}

export const updateWebVisibilityStatus = async (token: string, testimonialId: string, webVisibilityStatus: boolean) => {
    var body = {
        id: testimonialId,
        visibilityStatus: webVisibilityStatus
    }
    await fetch(clientConnectionBase + "testimonials", getOptionsForClientWithAuth("PUT", body, token));
}

export const uploadLogoToS3 = async (token: string, upload: File): Promise<string> => {
    var response = await fetch(clientConnectionBase + "document", getOptionsForClientWithAuth("POST", { fileName: upload.name, type: upload.type }, token)).then(resp => resp.json());
    await fetch(response.url, getOptionsForS3Upload(upload));
    var body = {
        logoS3Key: response.s3key
    };
    await fetch(clientConnectionBase + "userprofile", getOptionsForClientWithAuth("PUT", body, token));
    return response.s3key;
}


export const getTestimonialsForOurPurpose = async (): Promise<TestimonialForOurPurpose[]> => {
    var testimonials = await fetch(clientConnectionBase + "testimonialsforourpurpose", getOptionsForClientWithoutAuth("GET", null)).then((res) => res.json()).catch(err => console.log(err));
    if(testimonials){
        return Promise.all(testimonials.map(async (testimonial:any) => await ConvertServerModelToTestimonialForOurPurpose(testimonial)));
    }
    else {
        return [] as TestimonialForOurPurpose[];
    }
}