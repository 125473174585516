import React, { useState, useEffect, useContext, useRef } from "react";
import { UserProfileType, createUserProfileType } from "../../Domain/UserProfile";
import { AuthContext } from "../Authentication";
import { createUserProfile, getS3Item, updateUserProfile } from "../../Clients";
import { Button, CircularProgress, Grid, TextField, Box } from "@mui/material";
import { PatternFormat } from "react-number-format";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadLink from "react-download-link";
import { TaxationForm, UpdateTaxationForm } from "../../Domain/TaxationForm";
import { useNavigate } from "react-router";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import { RouteConstants } from "../../Constants/RouteConstants";
import { styled } from "@mui/material/styles";
import { Logo, UpdateLogo } from "../../Domain/Logo";
import CircleCropTool, { ICircleCropTool } from "./CircleCropTool";
import EditIcon from '@mui/icons-material/Edit';

const OuterDiv = styled(Box)(() => ({
  padding: "5vw",
  paddingLeft: "10vw",
}));

const AlignLeft = styled(Box)({
  textAlign: "left",
});

const ExtraLongInputBox = styled(TextField)({
  width: "50vw",
});

const MediumLongInputBox = styled(TextField)({
  width: "40vw",
});

const UploadGridItem = styled(Box)({
  paddingTop: "1em",
});

const AddTopPadding = styled(Box)({
  paddingTop: "1em",
});

const UserProfileForm: React.FC = () => {
  const userFromContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [profile, setProfile] = useState<UserProfileType>(createUserProfileType());
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [hasExistingProfile, setHasExistingProfile] = useState(false);

  const [currentTaxationForm, setCurrentTaxationForm] = useState<TaxationForm | undefined>();
  const [currentTaxationFormKey, setCurrentTaxationFormKey] = useState<string | undefined>();
  const [newTaxationForm, setNewTaxationForm] = useState<UpdateTaxationForm | undefined>();
  const [currentLogo, setCurrentLogo] = useState<Logo | undefined>();
  const [currentLogoKey, setCurrentLogoKey] = useState<string | undefined>();
  const logoFromCircleCropTool = useRef<ICircleCropTool>(null);

  useEffect(() => {
    setLoading(true);
    if (userFromContext && userFromContext.userProfile) {
      setProfile(userFromContext.userProfile);
      setHasExistingProfile(true);
      const fetchAuthData = async (accessToken: string, s3Key: string, logoS3Key: string, setLoading: (isLoading:boolean) => void) => {
        if (s3Key && s3Key !== "") {
          await getS3Item(accessToken, s3Key).then(response => {
            setCurrentTaxationForm({ s3Key: s3Key, file: response } as TaxationForm);
          });
        }
        if(logoS3Key && logoS3Key !== ""){
          await getS3Item(accessToken, logoS3Key).then(response => {
            setCurrentLogo({s3Key: logoS3Key, file: response} as Logo);
          });
        }
        setLoading(false);
      };
      if(userFromContext.accessToken && userFromContext.userProfile){
        fetchAuthData(userFromContext.accessToken, userFromContext.userProfile.s3Key, userFromContext.userProfile.logoS3Key, (isLoading: boolean) => setLoading(isLoading));
      }
  }
  }, [userFromContext]);

  const inputChange = (e: any, lengthRequirements?: number) => {
    if ((lengthRequirements && e.target.value.length <= 2) || !lengthRequirements) {
      setSingleFieldOnState(e.target.name, e.target.value);
    }
  };

  const handleFileUpload = (e: any) => {
    setNewTaxationForm(
      {
        file: e.target.files[0],
        s3Key: e.target.files[0].name,
        oldS3Key: currentTaxationFormKey
      } as UpdateTaxationForm
    );
  };

  const handleEditLogoClicked = () => {
    setCurrentLogoKey(currentLogo?.s3Key);
    setCurrentLogo(undefined);
  }

  const allRequiredFieldsValidated = () => {
    return (
      profile.organizationName !== "" &&
      profile.representativeName !== "" &&
      profile.federalEIN !== "" &&
      profile.streetAddress1 !== "" &&
      profile.city !== "" &&
      profile.state !== "" &&
      profile.zipCode !== "" &&
      ((currentTaxationForm && currentTaxationForm.file && currentTaxationForm.s3Key) || (newTaxationForm && newTaxationForm.file))
    );
  };

  const handleProfileSubmit = async () => {
    setLoading(true);
    if (allRequiredFieldsValidated()) {
      if (hasExistingProfile) {
        var copyOfProfile = profile;
        if (!profile.emailAddress) {
          copyOfProfile.emailAddress = userFromContext?.emailAddress ?? "";
          setProfile(copyOfProfile);
        }

        var newLogo = undefined as UpdateLogo | undefined;
        if(!currentLogo && logoFromCircleCropTool.current){
          var imageFile = await logoFromCircleCropTool.current.getImage();
          if(imageFile){
            newLogo = {file: imageFile, s3Key: imageFile.name, oldS3Key: currentLogoKey} as UpdateLogo;
          }
        }
        updateUserProfile(userFromContext?.accessToken!, copyOfProfile, newTaxationForm, newLogo).then(() => postSaveStuff()).catch(error => { setShowError(true); setLoading(false); });
      } else {
        profile.verificationStatus = VerificationStatus.New;
        profile.emailAddress = userFromContext?.emailAddress ?? "";
        createUserProfile(userFromContext?.accessToken!, profile, { s3Key: newTaxationForm?.s3Key, file: newTaxationForm?.file } as TaxationForm).then(() => postSaveStuff()).catch(error => { setShowError(true); setLoading(false); });
      }
    }
  };

  const postSaveStuff = () => {
    setLoading(false);
    userFromContext?.refreshUserProfile?.(profile);
    navigate(RouteConstants.PortalRouter);
  }

  const handleFileDelete = () => {
    setCurrentTaxationFormKey(currentTaxationForm?.s3Key);
    setNewTaxationForm(undefined);
    setCurrentTaxationForm(undefined);
  };

  function setSingleFieldOnState(name: string, value: any) {
    setProfile({
      ...profile,
      [name]: value,
    } as UserProfileType);
  }

  function GetLabelForDownloadLink(form?: UpdateTaxationForm | TaxationForm): string {
    var indexOfUnderscore = form?.s3Key?.indexOf("_") ?? -1;
    if (indexOfUnderscore === -1) {
      return form?.s3Key ?? "";
    }
    return form?.s3Key?.slice(indexOfUnderscore + 1) ?? "";
  }

  function GetFileNameForDownload(form?: UpdateTaxationForm | TaxationForm): string {
    return form?.s3Key ?? "defaultFileName";
  }

  return (
    <div>
      {loading && <CircularProgress />}
      {!loading && showError && <Grid container><Grid item>There was an error saving your profile. Please try again later.</Grid></Grid>}
      {!loading && (
        <OuterDiv>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12}>
              <Grid container>
                <AlignLeft>
                  <Grid container spacing={3}>
                    <Grid item>
                      <ExtraLongInputBox
                        name="organizationName"
                        id="organizationName"
                        value={profile.organizationName}
                        onChange={inputChange}
                        variant="outlined"
                        label="Organization Name"
                        required
                      />
                    </Grid>
                    <Grid item>
                      <PatternFormat
                        value={profile.federalEIN}
                        name="federalEIN"
                        customInput={TextField}
                        onValueChange={(values: any) => {
                          setSingleFieldOnState("federalEIN", values.formattedValue);
                        }}
                        label="Federal ID (EIN)"
                        variant="outlined"
                        format="##-#######"
                        required
                      />
                    </Grid>
                  </Grid>
                </AlignLeft>
                <AlignLeft>
                  <UploadGridItem>
                    <Grid container spacing={3}>
                      <Grid item>
                        <ExtraLongInputBox
                          name="representativeName"
                          id="representativeName"
                          value={profile.representativeName}
                          onChange={inputChange}
                          variant="outlined"
                          label="Representative Name"
                          required
                        />
                      </Grid>
                      <Grid item>
                        <PatternFormat
                          name="phoneNumber"
                          id="phoneNumber"
                          customInput={TextField}
                          value={profile.phoneNumber}
                          onChange={inputChange}
                          variant="outlined"
                          label="Phone Number"
                          required
                          format="(###) ###-####"
                        />
                      </Grid>
                    </Grid>
                  </UploadGridItem>
                </AlignLeft>
                <AlignLeft>
                  <UploadGridItem>
                    <MediumLongInputBox
                      name="website"
                      id="website"
                      value={profile.website}
                      onChange={inputChange}
                      variant="outlined"
                      label="Website"
                    />
                  </UploadGridItem>
                </AlignLeft>
                <AlignLeft>
                  <UploadGridItem>
                    <ExtraLongInputBox
                      name="streetAddress1"
                      id="streetAddress1"
                      value={profile.streetAddress1}
                      onChange={inputChange}
                      variant="outlined"
                      label="Address (line 1)"
                      required
                    />
                  </UploadGridItem>
                </AlignLeft>
                <AlignLeft>
                  <UploadGridItem>
                    <ExtraLongInputBox
                      name="streetAddress2"
                      id="streetAddress2"
                      value={profile.streetAddress2}
                      onChange={inputChange}
                      variant="outlined"
                      label="Address (line 2)"
                    />
                  </UploadGridItem>
                </AlignLeft>
                <AlignLeft>
                  <UploadGridItem>
                    <Grid container spacing={3}>
                      <Grid item>
                        <TextField name="city" id="city" value={profile.city} onChange={inputChange} variant="outlined" label="City" required />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="state"
                          id="state"
                          value={profile.state}
                          onChange={(event: any) => inputChange(event, 2)}
                          variant="outlined"
                          label="State"
                          required
                        />
                      </Grid>
                      <Grid item>
                        <PatternFormat
                          name="zipCode"
                          id="zipCode"
                          customInput={TextField}
                          value={profile.zipCode}
                          onChange={inputChange}
                          variant="outlined"
                          label="Zip Code"
                          required
                          format={profile.zipCode.length > 5 ? "#####-####" : "######"}
                        />
                      </Grid>
                    </Grid>
                  </UploadGridItem>
                </AlignLeft>
                {!currentTaxationForm && !newTaxationForm && (
                  <AlignLeft>
                    <UploadGridItem>
                      <span>Please provide a copy of your valid tax documentation (IRS 501(c)(3) determination letter, SS4, etc)</span>
                      <Button variant="contained" component="label" htmlFor="raised-button-file" style={{ marginLeft: "1em" }}>
                        Upload
                        <input type="file" hidden id="raised-button-file" multiple onChange={handleFileUpload} />
                      </Button>
                    </UploadGridItem>
                  </AlignLeft>
                )}
                {(currentTaxationForm || newTaxationForm) && (
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12} style={{ padding: "1em" }}>
                      <Grid container justifyContent="flex-start" wrap='nowrap'>
                        <Grid item>501(c)(3) determination letter, SS4, etc.</Grid>
                        <Grid item>
                          <Grid container wrap='nowrap'>
                            <Grid item style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                              <DownloadLink
                                label={GetLabelForDownloadLink(newTaxationForm ?? currentTaxationForm)}
                                filename={GetFileNameForDownload(newTaxationForm ?? currentTaxationForm)}
                                exportFile={() => newTaxationForm?.file ?? currentTaxationForm?.file}
                              />
                            </Grid>
                            <Grid item onClick={handleFileDelete}><DeleteIcon /></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {!currentLogo && (
                  <Grid item xs={12}>
                    <AlignLeft>
                      <UploadGridItem>
                        <span>Please upload a logo image for your organization that we can use to display on our list of donars and with any testimonials</span>
                        <Grid item style={{width:"100%"}}>
                          <CircleCropTool ref={logoFromCircleCropTool}/>
                        </Grid>
                      </UploadGridItem>
                    </AlignLeft>
                  </Grid>
                )}
                {(currentLogo) && (
                  <>
                    <Grid container direction="column" alignContent="flex-start">
                      <Grid item>Current Logo <EditIcon onClick={handleEditLogoClicked}/></Grid>
                      <Grid item><img alt="logo" src={URL.createObjectURL(currentLogo!.file!)} /></Grid>
                    </Grid>
                  </>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <AddTopPadding>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Button variant="contained" color="error" onClick={() => navigate(RouteConstants.PortalRouter)}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" color="primary" onClick={() => handleProfileSubmit()} disabled={!allRequiredFieldsValidated()}>
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </AddTopPadding>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </OuterDiv>
      )}
    </div>
  );
};

export default UserProfileForm;