import React, { useState, useEffect, useContext } from "react";
import { UserProfileType, createUserProfileType } from "../../Domain/UserProfile";
import { AuthContext } from "../Authentication";
import { getS3Item } from "../../Clients";
import { Grid } from "@mui/material";
import DownloadLink from "react-download-link";
import { TaxationForm } from "../../Domain/TaxationForm";
import LabelAndTextDisplay from "../shared/LabelAndTextDisplay";
import LabelAndAnyDisplay from "../shared/LabelAndAnyDisplay";
import { Logo } from "../../Domain/Logo";

const UserProfile: React.FC = () => {
  const userFromContext = useContext(AuthContext);

  const [profile, setProfile] = useState<UserProfileType>(createUserProfileType());
  const [currentTaxationForm, setCurrentTaxationForm] = useState<TaxationForm | undefined>()
  const [currentLogo, setCurrentLogo] = useState<Logo | undefined>()

  useEffect(() => {
    if (userFromContext?.userProfile) {
      if(userFromContext?.accessToken && userFromContext.userProfile.s3Key && userFromContext.userProfile.s3Key !== ""){
        getS3Item(userFromContext.accessToken, userFromContext.userProfile.s3Key).then(response => {
          setCurrentTaxationForm({s3Key: userFromContext.userProfile?.s3Key, file: response} as TaxationForm);
        });
      }
      if(userFromContext?.accessToken && userFromContext.userProfile.logoS3Key && userFromContext.userProfile.logoS3Key !== ""){
        getS3Item(userFromContext.accessToken, userFromContext.userProfile.logoS3Key).then(response => {
          setCurrentLogo({s3Key: userFromContext.userProfile?.logoS3Key, file: response} as Logo);
        });
      }
      setProfile(userFromContext.userProfile);
    }
  }, [userFromContext]);

  function GetLabelForDownloadLink(form?: TaxationForm): string {
    var indexOfUnderscore = form?.s3Key?.indexOf("_") ?? -1;
    if(indexOfUnderscore === -1){
      return form?.s3Key ?? "";
    }
    return form?.s3Key?.slice(indexOfUnderscore + 1) ?? "";
  }

  function GetFileNameForDownload(form?: TaxationForm): string {
    return form?.s3Key ?? "defaultFileName";
  }

  return (
    <Grid container style={{padding:"2em"}} justifyContent="flex-start">
        <Grid item xs={12}>
          {currentLogo && <LabelAndAnyDisplay align="left" value={<img alt="logo" src={URL.createObjectURL(currentLogo.file!)}/>} />}
          <LabelAndTextDisplay text={profile.organizationName} noPadding={true}/>
          <LabelAndTextDisplay text={profile.representativeName} noPadding={true}/>
          <LabelAndTextDisplay text={profile.phoneNumber} noPadding={true}/>
          <LabelAndTextDisplay text={userFromContext?.emailAddress}/>
          <LabelAndTextDisplay label="Federal ID (EIN)" text={profile.federalEIN} noPadding={true}/>
          <LabelAndTextDisplay label="Address" text={[profile.streetAddress1, profile.streetAddress2, `${profile.city}, ${profile.state} ${profile.zipCode}`]}/>
          <Grid container>
            <LabelAndAnyDisplay label="Tax Documentation" align="left" value={<DownloadLink
                  label={GetLabelForDownloadLink(currentTaxationForm)}
                  filename={GetFileNameForDownload(currentTaxationForm)}
                  exportFile={() => currentTaxationForm?.file}
              />} />
          </Grid>
          {profile.website && <LabelAndTextDisplay label="Website" text={profile.website}/>}
        </Grid>
    </Grid>
  );
};

export default UserProfile;
