import { Card, CardContent, CardHeader, Grid, styled} from "@mui/material";
import React from "react";

export interface ITestimonialCard {
  statement: string;
  logoUrl: string;
  organizationName: string;
  representativeName: string;
  dateEntered: Date;
}

const LogoStyled = styled('img')({
  width: '100%',
  height: '8vh',
  borderRadius: '50%',
});

const TestimonialCard: React.FC<ITestimonialCard> = ({statement, logoUrl, organizationName, representativeName, dateEntered}: ITestimonialCard) => {
  const avatar = (
    <Grid item>
      <LogoStyled src={logoUrl}/>
    </Grid>
  );

  const bothRepAndOrg = (
    <Grid container>
      <Grid item xs={12}>{organizationName}</Grid>
      <Grid item xs={12}>{representativeName}</Grid>
    </Grid>
  );

  return (
    <Card sx={{ display: 'flex', flexDirection: {
      xs: "column",
      sm: "row",
    }}}>
      <CardHeader
        sx={{ textAlign: 'left', flex: '0 1 auto' }} 
        titleTypographyProps={{variant:'h6'}}
        subheaderTypographyProps={{variant:'h6'}}
        avatar={avatar}
        subheader={dateEntered.toLocaleDateString()}
        title={bothRepAndOrg}/>
      <CardContent 
        sx={{ width: "100%", textAlign:"left", fontSize:"2em"}}>
        {statement}
      </CardContent>
    </Card>
  );
};

export default TestimonialCard;
