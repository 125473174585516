import { Grid } from "@mui/material";
import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";

interface CirclePosition {
  x: number;
  y: number;
}

export interface ICircleCropTool {
  getImage: () => Promise<File | undefined>;
}

const CircleCropTool = forwardRef<ICircleCropTool>((_, ref) => {
  const [image, setImage] = useState<string | null>(null);
  const [circlePosition, setCirclePosition] = useState<CirclePosition>({ x: 0, y: 0 });
  const circleSize = 150;
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const [scale, setScale] = useState<number>(1); // Scale factor for zooming
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useImperativeHandle(ref, () => ({
    async getImage() {
      return await handleCrop().then((file: File | undefined) => file);
    }
  }));

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const drawImageToCanvas = (): void => {
    if (!canvasRef.current || !image) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const img = new Image();
    img.src = image;
    img.onload = () => {
      const scaledWidth = img.width * scale;
      const scaledHeight = img.height * scale;

      canvas.width = scaledWidth;
      canvas.height = scaledHeight;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);

      setImageDimensions({ width: scaledWidth, height: scaledHeight });

      // Center the circle initially
      setCirclePosition({
        x: scaledWidth / 2 - circleSize / 2,
        y: scaledHeight / 2 - circleSize / 2,
      });
    };
  };

  const handleCrop = (): Promise<File | undefined> => {
    if (!canvasRef.current || !image) return Promise.resolve(undefined);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return Promise.resolve(undefined);

    const outputCanvas = document.createElement("canvas");
    const outputCtx = outputCanvas.getContext("2d");
    if (!outputCtx) return Promise.resolve(undefined);

    // Ensure the image is drawn on the canvas
    drawImageToCanvas();

    const { x, y } = circlePosition;
    const radius = circleSize / 2;

    outputCanvas.width = circleSize;
    outputCanvas.height = circleSize;

    outputCtx.clearRect(0, 0, outputCanvas.width, outputCanvas.height);
    outputCtx.save();
    outputCtx.beginPath();
    outputCtx.arc(radius, radius, radius, 0, Math.PI * 2);
    outputCtx.closePath();
    outputCtx.clip();

    outputCtx.drawImage(
      canvas,
      x,
      y,
      circleSize,
      circleSize,
      0,
      0,
      circleSize,
      circleSize
    );

    outputCtx.restore();

    // Convert the canvas content to a Blob and then to a File
    return new Promise((resolve) => {
      outputCanvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "cropped-image.png", { type: "image/png" });
          resolve(file);
        } else {
          resolve(undefined);
        }
      }, "image/png");
    });
  };

  const handleDrag = (e: DraggableEvent, data: DraggableData): void => {
    // Update circle position when dragged
    setCirclePosition({ x: data.x, y: data.y });
  };

  const handleZoom = (e: React.WheelEvent<HTMLDivElement>): void => {
    e.preventDefault();

    const newScale = scale + (e.deltaY < 0 ? 0.1 : -0.1);
    if (newScale > 0.5 && newScale <= 3) {
      setScale(newScale);
    }
  };

  useEffect(() => {
    drawImageToCanvas();
  }, [image, scale]);

  return (
    <div>
        <Grid container>
            <Grid item xs={12}><input type="file" accept="image/*" onChange={handleImageUpload} /></Grid>
            <Grid item xs={12}>
                <div
                    onWheel={handleZoom}
                    style={{
                    position: "relative",
                    display: "inline-block",
                    border: "1px solid #ccc",
                    overflow: "hidden",
                    width: imageDimensions.width,
                    height: imageDimensions.height,
                    }}
                >
                    <canvas
                        ref={canvasRef}
                        style={{
                            display: image ? "block" : "none",
                            position: "absolute",
                            zIndex: 1,
                        }}
                    />
                    {image && (
                        <Draggable
                            bounds={{
                            left: 0,
                            top: 0,
                            right: imageDimensions.width - circleSize,
                            bottom: imageDimensions.height - circleSize,
                            }}
                            position={circlePosition}
                            onDrag={handleDrag}
                        >
                            <div
                            style={{
                                position: "absolute",
                                width: `${circleSize}px`,
                                height: `${circleSize}px`,
                                borderRadius: "50%",
                                border: "2px solid white",
                                background: "rgba(0, 0, 0, 0.3)",
                                cursor: "move",
                                zIndex: 2, // Ensure circle is above the canvas
                            }}
                            ></div>
                        </Draggable>
                    )}
                </div>
            </Grid>
        </Grid>
        </div>
  );
});

export default CircleCropTool;
