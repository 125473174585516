import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { UserAuthContext } from '../Domain/User';
import { checkIsAdmin, checkIsReadOnlyAdmin } from '../Helpers/ClaimHelper';
import { GetValue, StorageKey, StoreValue, ClearAll } from '../Clients';
import { getUserProfile } from '../Clients';
import { GetLogOutWithRedirectParams } from "../Constants/Auth0Constants";
import { UserProfileType } from '../Domain/UserProfile';

export const AuthContext = createContext<UserAuthContext | undefined>(undefined);

interface IAuthentication {
    children?: ReactNode | ReactNode[];
}

export const Authentication = ({ children }: IAuthentication) => {
  const { getAccessTokenSilently, getIdTokenClaims, user, isAuthenticated, logout, isLoading } = useAuth0();
  var userAuthContextDataFromCache = GetValue(StorageKey.UserInfo);
  
  const refreshUserProfile = useCallback((userProfile: UserProfileType) => {
    var getUserAuthFromCache = GetValue(StorageKey.UserInfo);
    if (getUserAuthFromCache) {
      var userDataForContext = JSON.parse(getUserAuthFromCache) as UserAuthContext;
      userDataForContext.userProfile = userProfile;
      userDataForContext.logOut = logOut;
      setUserAuthContext(userDataForContext);
      StoreValue(StorageKey.UserInfo, JSON.stringify(userDataForContext));
    } else {
      ClearAll();
    }
  }, []);

  const logOut = useCallback((redirectLocation: string) => {
    logout(GetLogOutWithRedirectParams(redirectLocation ?? ""));
    ClearAll();
    var userAuthContextNew = {
      accessToken: undefined,
      emailAddress: undefined,
      isReadWriteAdministrator: undefined,
      isReadOnlyAdministrator: undefined,
      userProfile: undefined,
      isLoading: true,
      refreshUserProfile: undefined,
      logOut: undefined
    } as UserAuthContext;
    setUserAuthContext(userAuthContextNew);
    StoreValue(StorageKey.UserInfo, JSON.stringify(userAuthContextNew));
  }, [logout]);

  const [userAuthContext, setUserAuthContext] = useState<UserAuthContext>(
    userAuthContextDataFromCache ?
    JSON.parse(userAuthContextDataFromCache) as UserAuthContext 
    : {
      accessToken: undefined,
      emailAddress: undefined,
      isReadWriteAdministrator: undefined,
      isReadOnlyAdministrator: undefined,
      userProfile: undefined,
      isLoading: true,
      refreshUserProfile: refreshUserProfile,
      logOut: logOut
    } as UserAuthContext
  );
  
  useEffect(() => {
    const fetchAuthData = async () => {
      if (isAuthenticated) { // Only proceed if the user is authenticated
        try {
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
          const isReadWriteAdministrator = checkIsAdmin(idTokenClaims);
          const isReadOnlyAdministrator = checkIsReadOnlyAdmin(idTokenClaims);
          const emailAddress = user?.email;
          const userProfile = await getUserProfile(accessToken).catch(e => { return undefined; });
          const userAuthContextNew = { accessToken, isReadWriteAdministrator, isReadOnlyAdministrator, emailAddress, userProfile: userProfile, isLoading: false, refreshUserProfile: refreshUserProfile, logOut: logOut } as UserAuthContext;
          setUserAuthContext(userAuthContextNew);
          StoreValue(StorageKey.UserInfo, JSON.stringify(userAuthContextNew));
        } catch (error) {
          console.error('Error fetching authentication data:', error);
        }
      } else {
        const userAuthContextNew = {
          accessToken: undefined,
          isReadWriteAdministrator: undefined,
          isReadOnlyAdministrator: undefined,
          emailAddress: undefined,
          userProfile: undefined,
          isLoading: isLoading,
          refreshUserProfile: refreshUserProfile,
          logOut: logOut
        } as UserAuthContext;
        setUserAuthContext(userAuthContextNew);
        StoreValue(StorageKey.UserInfo, JSON.stringify(userAuthContextNew));
      }
    };
    fetchAuthData();
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user, refreshUserProfile, logOut, isLoading]);

  return (
    <>
      <AuthContext.Provider value={userAuthContext}>
        {children}
      </AuthContext.Provider>
    </>
  );
};
