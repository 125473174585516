export enum RouteConstants {
  Home = "/",
  Admin = "/admin",
  Apply = "/apply",
  Board = "/board",
  Contact = "/contact",
  Donate = "/donate",
  History = "/history",
  NewsAndPress = "/news-and-press",
  NotRegistered = "/not-registered",
  OurPurpose = "/our-purpose",
  Portal = "/portal",
  PortalRouter = "/portal-router",
  PrivacyPolicy = "/privacy-policy",
  Profile = "/profile",
  RegistrationSteps = "/registration-steps",
  SiteTerms = "/site-terms",
  SpeakingOpportunities = "/speaking-opportunities",
  StateDisclosures = "/state-disclosures",
  Login = "/login",
  AddTestimonial = "/add-testimonial"
}