import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TestimonialForOurPurpose } from "../Domain/Testimonial";
import { getTestimonialsForOurPurpose } from "../Clients/BeTheChangeApi";
import TestimonialCard from "./TestimonialPage/TestimonialCard";
import peopleLaughing from '../Images/PeopleLaughing.png'

const MainGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2em"
});

const WorkBlock = styled(Grid)({
  padding: "1em",
});

const OurPurpose: React.FC = () => {
  const [testimonials, setTestimonials] = useState<TestimonialForOurPurpose[]>();

  useEffect(() => {
    getTestimonialsForOurPurpose().then(testimonialList => {
      setTestimonials(testimonialList);
    });
  }, []);

  return (
    <MainGrid container>
      <Grid item xs={12}>
        <h1>Our Purpose</h1>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          {testimonials && testimonials.map(testimonial => {
            var logoUrl = "";
            if(testimonial.logo){
              logoUrl = URL.createObjectURL(testimonial.logo);
            }
            return (
              <TestimonialCard statement={testimonial.statement} logoUrl={logoUrl} organizationName={testimonial.organizationName} representativeName={testimonial.representativeName} dateEntered={new Date(testimonial.dateCreated)}/>
            );
          })}
          {(!testimonials || testimonials.length === 0) && (
            <img src={peopleLaughing} alt="people laughing" />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="center">
              <WorkBlock item xs={12}>
                Honeybees are known for their astounding teamwork. They might be
                tiny but by working together, each doing its own small part, these
                industrious bees build fantastic honeycombs that provide food and
                shelter for their huge families. They do not do it alone. They do
                it by working together. Each bee looks out for the good of the
                hive and the hive looks out for each bee.
              </WorkBlock>
              <WorkBlock item xs={12}>
                This is the concept of Be The Change Gulf Coast. We recognize that
                sometimes people just need a little help and by working together
                we can each do a little something to achieve the bigger goal.
              </WorkBlock>
              <WorkBlock item xs={12}>
                Be The Change Gulf Coast is a 501c3 charity committed to helping
                members of our community who find themselves struggling when an
                unexpected challenge forces them to choose between necessities.
                Founded by Shelli Bell, who through her work with several
                non-profits has seen firsthand how $100 can change the trajectory
                of a person’s life, Be The Change Gulf Coast seeks to help those
                who are trying to help themselves.
              </WorkBlock>
              <WorkBlock item xs={12}>
                The people we help are faced with hard choices like buying
                groceries or medicine. Because they can’t afford both. These are
                not luxury items; they are every day needs that a lot of us take
                for granted. For families with one or two working parents that
                just cannot make ends meet when there is a bump in the proverbial
                road, our assistance can make all the difference.
              </WorkBlock>
              <WorkBlock item xs={12}>
                Please join our hive! It’s easy to help. We have an initial
                mission to find 5,000 people in our area willing to give just $2 a
                month on auto draft. That’s right… just TWO DOLLARS per month.
                This amount is so small you’ll never miss it. But when 5,000 of us
                help each month, that’s $10,000 and THAT means a lot. Think about
                it, you’ve probably got $2.00 worth of loose change in your car’s
                cup holder right now. This small amount will barely make you blink
                but combined with others… collectively we can make a huge
                difference.
              </WorkBlock>
              <WorkBlock item xs={12}>
                Please see our personal stories tab for testimonials and please,
                work with us to help members of your community who need it. Who
                knows, maybe one day you’ll find yourself struggling in this same
                way and they will all be there to help you.
              </WorkBlock>
          </Grid>
        </Grid> 
      </Grid>
    </MainGrid>
  );
};

export default OurPurpose;